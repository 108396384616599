/* eslint-disable */
import * as Types from './generated-types';

import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
export const CountryFragmentDoc = gql`
    fragment Country on Country {
  id
  languageCode
  code
  name
  enabled
  customFields
}
    `;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
  id
  createdAt
  updatedAt
  fullName
  streetLine1
  streetLine2
  city
  province
  postalCode
  country {
    ...Country
  }
  customFields
}
    `;
export const CompanyAddressFragmentDoc = gql`
    fragment CompanyAddress on CompanyAddress {
  id
  fullName
  streetLine1
  streetLine2
  province
  countryCode
  city
  postalCode
  phoneNumber
  defaultBillingAddress
  defaultShippingAddress
  customFields {
    erpId
  }
}
    `;
export const BreadcrumbFragmentDoc = gql`
    fragment Breadcrumb on CollectionBreadcrumb {
  name
  id
  slug
}
    `;
export const ActiveOrderFragmentDoc = gql`
    fragment ActiveOrder on Order {
  id
  state
  updatedAt
  createdAt
  total
  totalWithTax
  subTotal
  subTotalWithTax
  shipping
  currencyCode
  shippingWithTax
  totalQuantity
  lines {
    id
    linePrice
    discountedLinePrice
    unitPrice
    quantity
    productVariant {
      currencyCode
      id
      name
      sku
      price
      assets {
        id
        source
      }
    }
  }
}
    `;
export const OrderAddressFragmentDoc = gql`
    fragment OrderAddress on OrderAddress {
  fullName
  company
  streetLine1
  streetLine2
  city
  province
  postalCode
  country
  countryCode
  phoneNumber
  customFields
}
    `;
export const ProductVariantCustomFieldFragmentDoc = gql`
    fragment ProductVariantCustomField on ProductVariantCustomFields {
  productDetailName @include(if: $isPreRender)
  infoText @include(if: $isPreRender)
  customAttributes @include(if: $isPreRender) {
    itemText
    itemHeadline
  }
  onSiteImages @skip(if: $isPreRender) {
    name
    source
  }
  systemInformationItems @skip(if: $isPreRender) {
    itemText
    itemHeadline
  }
  additionalProducts @skip(if: $isPreRender) {
    id
    name
  }
}
    `;
export const AssetFragmentDoc = gql`
    fragment Asset on Asset {
  width
  height
  source
}
    `;
export const ProductVariantFragmentDoc = gql`
    fragment ProductVariant on ProductVariant {
  id
  name
  sku
  stockLevel
  currencyCode
  price
  options {
    code
    group {
      name
    }
  }
  customFields @include(if: $withCustomFields) {
    ...ProductVariantCustomField
  }
  assets {
    ...Asset
  }
}
    `;
export const CustomerDataFragmentDoc = gql`
    fragment CustomerData on Customer {
  id
  firstName
  lastName
  emailAddress
  title
  phoneNumber
  customFields @include(if: $withCustomFields) {
    salutation
  }
}
    `;
export const OfferPriceFragmentDoc = gql`
    fragment OfferPrice on OfferPrice {
  id
  price
  currencyCode
  productVariant {
    name
    price
    product {
      name
    }
    id
    sku
    featuredAsset {
      ...Asset
    }
  }
}
    `;
export const OfferFragmentFragmentDoc = gql`
    fragment OfferFragment on Offer {
  id
  reference
  status
  createdAt
  number
  date
  address
  subject
  headerText
  footerText
  termsOfDelivery
  termsOfPayment
  additionalDiscount
  customOfferLines {
    id
    name
    price
    quantity
    taxRate
    discount
    positionTotal
    alternative
  }
  order {
    id
    totalWithTax
    total
    subTotal
    subTotalWithTax
    lines {
      customFields {
        offerOrderLine {
          price
          positionTotal
        }
      }
    }
  }
}
    `;
 const AddItemToOrderDocument = gql`
    mutation AddItemToOrder($productVariantId: ID!, $quantity: Int!, $withCustomFields: Boolean = false) {
  addItemToOrder(productVariantId: $productVariantId, quantity: $quantity) {
    __typename
    ... on Order {
      id
      customer {
        ...CustomerData
      }
      state
    }
    ... on OrderModificationError {
      errorCode
      message
      __typename
    }
    ... on OrderLimitError {
      message
      maxItems
      __typename
    }
    ... on NegativeQuantityError {
      errorCode
      message
      __typename
    }
    ... on InsufficientStockError {
      errorCode
      message
      quantityAvailable
      __typename
    }
  }
}
    ${CustomerDataFragmentDoc}`;
 const AddItemToOrderBySkuDocument = gql`
    mutation addItemToOrderBySku($input: AddItemToOrderBySkuInput!) {
  addItemToOrderBySku(input: $input) {
    ... on Order {
      id
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on Node {
      id
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const AddPaymentDocument = gql`
    mutation AddPayment($input: PaymentInput!) {
  addPaymentToOrder(input: $input) {
    __typename
    ...ActiveOrder
    ... on Order {
      id
      code
    }
    ... on OrderPaymentStateError {
      errorCode
      message
    }
    ... on IneligiblePaymentMethodError {
      errorCode
      message
    }
    ... on PaymentFailedError {
      errorCode
      message
    }
    ... on PaymentDeclinedError {
      errorCode
      message
      paymentErrorMessage
    }
    ... on OrderStateTransitionError {
      errorCode
      message
      transitionError
      fromState
      toState
    }
    ... on NoActiveOrderError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    ${ActiveOrderFragmentDoc}`;
 const AddToWishlistDocument = gql`
    mutation AddToWishlist($productVariantId: ID!, $wishlistId: ID) {
  addToWishlist(productVariantId: $productVariantId, wishlistId: $wishlistId) {
    id
    itemCount
    items {
      id
    }
  }
}
    `;
 const UpdateCompanyAddressDocument = gql`
    mutation UpdateCompanyAddress($input: UpdateCompanyAddressInput!) {
  updateCompanyAddress(address: $input) {
    __typename
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on CompanyAddress {
      id
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on Node {
      id
    }
  }
}
    `;
 const DeleteCompanyAddressDocument = gql`
    mutation DeleteCompanyAddress($id: ID!) {
  deleteCompanyAddress(addressId: $id) {
    __typename
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on Success {
      success
    }
  }
}
    `;
 const CreateCompanyAddressDocument = gql`
    mutation CreateCompanyAddress($input: CreateCompanyAddressInput!) {
  addCompanyAddress(address: $input) {
    __typename
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on CompanyAddress {
      id
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on Node {
      id
    }
  }
}
    `;
 const AdjustOrderLineDocument = gql`
    mutation AdjustOrderLine($orderLineId: ID!, $quantity: Int!) {
  adjustOrderLine(orderLineId: $orderLineId, quantity: $quantity) {
    ... on Order {
      id
      totalQuantity
      lines {
        quantity
      }
    }
    ... on OrderModificationError {
      errorCode
      message
    }
    ... on OrderLimitError {
      errorCode
      message
    }
    ... on NegativeQuantityError {
      errorCode
      message
    }
    ... on InsufficientStockError {
      errorCode
      message
      quantityAvailable
    }
  }
}
    `;
 const ApplyCouponCodeDocument = gql`
    mutation ApplyCouponCode($couponCode: String!) {
  applyCouponCode(couponCode: $couponCode) {
    ... on Order {
      id
    }
    ... on CouponCodeExpiredError {
      errorCode
      message
      couponCode
    }
    ... on CouponCodeInvalidError {
      errorCode
      message
      couponCode
    }
    ... on CouponCodeLimitError {
      errorCode
      message
      couponCode
      limit
    }
    ... on Node {
      id
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const CreateAddressDocument = gql`
    mutation CreateAddress($city: String!, $company: String!, $countryCode: String!, $fullName: String!, $postalCode: String!, $province: String!, $streetLine1: String!, $streetLine2: String!, $phoneNumber: String!) {
  createCustomerAddress(
    input: {city: $city, company: $company, countryCode: $countryCode, fullName: $fullName, postalCode: $postalCode, province: $province, streetLine1: $streetLine1, streetLine2: $streetLine2, phoneNumber: $phoneNumber}
  ) {
    id
  }
}
    `;
 const CreateAssetDocument = gql`
    mutation CreateAsset($input: [CreateAssetInput!]!) {
  createAssets(input: $input) {
    __typename
    ... on Asset {
      id
      preview
      source
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on MimeTypeError {
      errorCode
      message
      mimeType
    }
  }
}
    `;
 const CreateCustomerAddressDocument = gql`
    mutation createCustomerAddress($input: CreateAddressInput!) {
  createCustomerAddress(input: $input) {
    id
    createdAt
  }
}
    `;
 const CreateOfferDocument = gql`
    mutation CreateOffer($address: String!, $subject: String!, $headerText: String!, $footerText: String!, $reference: String!, $positions: [CreateOfferPositionInput!], $termsOfDelivery: String!, $termsOfPayment: String!, $date: DateTime!, $number: String!, $additionalDiscount: Int) {
  createOffer(
    input: {address: $address, subject: $subject, headerText: $headerText, footerText: $footerText, reference: $reference, positions: $positions, termsOfPayment: $termsOfPayment, termsOfDelivery: $termsOfDelivery, date: $date, number: $number, additionalDiscount: $additionalDiscount}
  ) {
    __typename
    ... on Offer {
      id
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const CreateWishlistDocument = gql`
    mutation CreateWishlist($name: String!, $description: String) {
  createWishlist(input: {name: $name, description: $description}) {
    id
    name
  }
}
    `;
 const DeleteOfferDocument = gql`
    mutation DeleteOffer($id: ID!) {
  deleteOffer(offerId: $id) {
    __typename
    ... on Success {
      success
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on NotAllowedError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const DeleteWishlistDocument = gql`
    mutation DeleteWishlist($id: ID!) {
  deleteWishlist(id: $id) {
    result
    message
  }
}
    `;
 const LoginUserDocument = gql`
    mutation LoginUser($username: String!, $password: String!, $rememberMe: Boolean!) {
  login(username: $username, password: $password, rememberMe: $rememberMe) {
    __typename
    ... on CurrentUser {
      id
      identifier
    }
    ... on InvalidCredentialsError {
      errorCode
      message
    }
    ... on NotVerifiedError {
      errorCode
      message
    }
    ... on NativeAuthStrategyError {
      errorCode
      message
    }
  }
}
    `;
 const LogoutUserDocument = gql`
    mutation LogoutUser {
  logout {
    success
    __typename
  }
}
    `;
 const SubscribeToNewsletterDocument = gql`
    mutation SubscribeToNewsletter($input: NewsletterAddressInput!) {
  subscribeToNewsletter(input: $input) {
    success
  }
}
    `;
 const UnsubscribeToNewsletterDocument = gql`
    mutation UnsubscribeToNewsletter($input: NewsletterAddressInput!) {
  unsubscribeToNewsletter(input: $input) {
    success
  }
}
    `;
 const CreateOfferPriceDocument = gql`
    mutation CreateOfferPrice($productVariantId: ID!, $offerPrice: Int!, $currencyCode: String!) {
  createOfferPrice(
    input: {productVariantId: $productVariantId, price: $offerPrice, currencyCode: $currencyCode}
  ) {
    ... on OfferPrice {
      id
      price
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
  }
}
    `;
 const UpdateOfferPriceDocument = gql`
    mutation UpdateOfferPrice($id: ID!, $price: Int!) {
  updateOfferPrice(input: {offerPriceId: $id, price: $price}) {
    __typename
    ... on OfferPrice {
      id
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const DeleteOfferPriceDocument = gql`
    mutation DeleteOfferPrice($id: ID!) {
  deleteOfferPrice(offerPriceId: $id) {
    __typename
    ... on Success {
      success
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const CreateOfferPricesDocument = gql`
    mutation CreateOfferPrices($input: [CreateOfferPriceInput!]!) {
  createOfferPrices(input: $input) {
    __typename
    ... on OfferPriceList {
      totalItems
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const PasswordResetDocument = gql`
    mutation PasswordReset($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token) {
    ... on CurrentUser {
      __typename
      id
    }
    ... on ErrorResult {
      __typename
      errorCode
      message
    }
    ... on NotVerifiedError {
      __typename
      errorCode
      message
    }
    ... on PasswordResetTokenExpiredError {
      __typename
      errorCode
      message
    }
    ... on PasswordResetTokenInvalidError {
      __typename
      errorCode
      message
    }
    ... on PasswordValidationError {
      __typename
      errorCode
      message
      validationErrorMessage
    }
  }
}
    `;
 const RegisterDocument = gql`
    mutation Register($input: B2BRegisterInput!) {
  registerB2BCustomer(input: $input) {
    __typename
    ... on Success {
      success
    }
    ... on MissingPasswordError {
      errorCode
      message
    }
    ... on PasswordValidationError {
      errorCode
      message
    }
    ... on NativeAuthStrategyError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on InvalidVatError {
      errorCode
      message
    }
  }
}
    `;
 const RemoveAllOrderLinesDocument = gql`
    mutation RemoveAllOrderLines {
  removeAllOrderLines {
    __typename
    ... on Order {
      id
    }
    ... on OrderModificationError {
      message
    }
  }
}
    `;
 const RemoveAllOrderLinesAndStockMovementsDocument = gql`
    mutation removeAllOrderLinesAndStockMovements {
  removeAllOrderLinesAndStockMovements {
    __typename
    ... on Order {
      id
    }
    ... on OrderModificationError {
      errorCode
      message
    }
    ... on Node {
      id
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const RemoveCouponCodeDocument = gql`
    mutation RemoveCouponCode($couponCode: String!) {
  removeCouponCode(couponCode: $couponCode) {
    code
    ... on Order {
      id
    }
  }
}
    `;
 const RemoveFromWishlistDocument = gql`
    mutation RemoveFromWishlist($itemIds: [ID!]!, $wishlistId: ID!) {
  removeFromWishlist(itemIds: $itemIds, wishlistId: $wishlistId) {
    id
    name
  }
}
    `;
 const RemoveItemFromOrderDocument = gql`
    mutation RemoveItemFromOrder($orderLineID: ID!) {
  removeOrderLine(orderLineId: $orderLineID) {
    ... on Order {
      id
    }
    ... on OrderModificationError {
      errorCode
      message
    }
  }
}
    `;
 const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($email: String!) {
  requestPasswordReset(emailAddress: $email) {
    ... on Success {
      __typename
      success
    }
    ... on ErrorResult {
      __typename
      errorCode
      message
    }
    ... on NativeAuthStrategyError {
      __typename
      errorCode
      message
    }
  }
}
    `;
 const SetActiveCompanyDocument = gql`
    mutation SetActiveCompany($companyId: ID!) {
  setActiveCompany(companyId: $companyId) {
    ... on NotAllowedError {
      errorCode
      message
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on Customer {
      id
      firstName
      lastName
    }
  }
}
    `;
 const SetShippingAddressDocument = gql`
    mutation SetShippingAddress($input: CreateAddressInput!) {
  setOrderShippingAddress(input: $input) {
    ...ActiveOrder
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    ${ActiveOrderFragmentDoc}`;
 const SetBillingAddressDocument = gql`
    mutation SetBillingAddress($input: CreateAddressInput!) {
  setOrderBillingAddress(input: $input) {
    ...ActiveOrder
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    ${ActiveOrderFragmentDoc}`;
 const SetOrderCustomFieldsDocument = gql`
    mutation SetOrderCustomFields($comment: String, $reference: String, $commission: String) {
  setOrderCustomFields(
    input: {customFields: {comment: $comment, reference: $reference, commission: $commission}}
  ) {
    ... on Order {
      id
    }
    ... on NoActiveOrderError {
      errorCode
      message
    }
  }
}
    `;
 const SetOrderShippingMethodDocument = gql`
    mutation SetOrderShippingMethod($shippingMethodId: [ID!]!) {
  setOrderShippingMethod(shippingMethodId: $shippingMethodId) {
    ...ActiveOrder
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    ${ActiveOrderFragmentDoc}`;
 const ToogleCustomerSpecificPriceDocument = gql`
    mutation ToogleCustomerSpecificPrice {
  toggleCustomerSpecificPrices {
    ... on Order {
      id
      total
      lines {
        quantity
        unitPrice
        productVariant {
          price
          id
        }
      }
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const TransformOfferToCartDocument = gql`
    mutation transformOfferToCart($offerId: ID!) {
  transformOfferToCart(offerId: $offerId) {
    __typename
    ... on Success {
      success
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const TransitionOrderDocument = gql`
    mutation TransitionOrder($state: String!) {
  transitionOrderToState(state: $state) {
    ...ActiveOrder
    ... on ErrorResult {
      errorCode
      message
    }
    ... on OrderStateTransitionError {
      errorCode
      message
      transitionError
      fromState
      toState
    }
  }
}
    ${ActiveOrderFragmentDoc}`;
 const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($title: String, $firstName: String!, $lastName: String!, $phoneNumber: String, $salutation: String) {
  updateCustomer(
    input: {title: $title, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, customFields: {salutation: $salutation}}
  ) {
    id
  }
}
    `;
 const UpdateCustomerPasswordDocument = gql`
    mutation UpdateCustomerPassword($currentPassword: String!, $newPassword: String!) {
  updateCustomerPassword(
    currentPassword: $currentPassword
    newPassword: $newPassword
  ) {
    __typename
    ... on Success {
      success
    }
    ... on InvalidCredentialsError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on NativeAuthStrategyError {
      errorCode
      message
    }
    ... on PasswordValidationError {
      errorCode
      message
    }
  }
}
    `;
 const RequestUpdateCustomerEmailDocument = gql`
    mutation RequestUpdateCustomerEmail($password: String!, $newEmailAddress: String!) {
  requestUpdateCustomerEmailAddress(
    password: $password
    newEmailAddress: $newEmailAddress
  ) {
    __typename
    ... on Success {
      success
    }
    ... on InvalidCredentialsError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
    ... on NativeAuthStrategyError {
      errorCode
      message
    }
    ... on EmailAddressConflictError {
      errorCode
      message
    }
  }
}
    `;
 const UpdateOfferDocument = gql`
    mutation UpdateOffer($id: ID!, $address: String, $subject: String, $headerText: String, $footerText: String, $reference: String, $positions: [CreateOfferPositionInput!], $termsOfDelivery: String, $termsOfPayment: String, $date: DateTime, $number: String, $status: OfferStatus, $additionalDiscount: Int) {
  updateOffer(
    input: {id: $id, address: $address, subject: $subject, headerText: $headerText, footerText: $footerText, reference: $reference, positions: $positions, termsOfPayment: $termsOfPayment, termsOfDelivery: $termsOfDelivery, date: $date, number: $number, status: $status, additionalDiscount: $additionalDiscount}
  ) {
    __typename
    ... on Offer {
      id
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on NotAllowedError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const UpdateOfferSettingsDocument = gql`
    mutation UpdateOfferSettings($color: String, $vat: String, $iban: String, $accountNumber: String, $bankName: String, $bankCode: String, $address: String, $email: String, $phoneNumber: String, $companyRegister: String, $logo: String) {
  updateOfferSettings(
    input: {color: $color, vat: $vat, iban: $iban, accountNumber: $accountNumber, bankName: $bankName, bankCode: $bankCode, address: $address, email: $email, phoneNumber: $phoneNumber, companyRegister: $companyRegister, logo: $logo}
  ) {
    __typename
    ... on OfferSettings {
      id
    }
    ... on EntityNotFoundError {
      errorCode
      message
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    `;
 const UpdateWishlistDocument = gql`
    mutation UpdateWishlist($id: ID!, $name: String!, $description: String) {
  updateWishlist(input: {id: $id, name: $name, description: $description}) {
    id
  }
}
    `;
 const ActiveChannelDocument = gql`
    query ActiveChannel {
  activeChannel {
    id
    code
    currencyCode
    pricesIncludeTax
    token
    defaultLanguageCode
    defaultShippingZone {
      id
      name
      members {
        id
        name
      }
    }
  }
}
    `;
 const ActiveCompanyDocument = gql`
    query ActiveCompany {
  activeCompany {
    id
    name
    customFields {
      erpId
      vat
      ust
      isConfiguratorAdmin
    }
    termsOfPayment {
      paymentId
      contactPerson
      text
      amount
    }
    discounts {
      id
      discountType
      discountGroup
      discountType2
      customerDiscountPercentages {
        id
        value
        index
      }
    }
  }
}
    `;
 const ActiveCompanyAddressesDocument = gql`
    query ActiveCompanyAddresses {
  activeCompany {
    addresses {
      ...CompanyAddress
    }
  }
}
    ${CompanyAddressFragmentDoc}`;
 const ContactPersonIdDocument = gql`
    query ContactPersonId {
  activeCompany {
    termsOfPayment {
      contactPerson
    }
  }
}
    `;
 const ActiveCustomerDocument = gql`
    query ActiveCustomer($withCustomFields: Boolean = false) {
  activeCustomer {
    ...CustomerData
  }
}
    ${CustomerDataFragmentDoc}`;
 const ActiveCustomerRolesDocument = gql`
    query ActiveCustomerRoles {
  activeCustomer {
    groups {
      id
      name
    }
    customFields {
      useCSP
    }
  }
}
    `;
 const ActiveCustomerForCheckoutDocument = gql`
    query ActiveCustomerForCheckout {
  activeCustomer {
    firstName
    lastName
    addresses {
      ...Address
    }
  }
}
    ${AddressFragmentDoc}
${CountryFragmentDoc}`;
 const ActiveCustomerOrdersDocument = gql`
    query ActiveCustomerOrders {
  activeCustomer {
    orders {
      items {
        id
        code
        state
        total
        currencyCode
        orderPlacedAt
        customFields {
          erpId
        }
      }
    }
  }
}
    `;
 const ActiveOrderCartDocument = gql`
    query ActiveOrderCart {
  activeOrder {
    state
    subTotal
    shipping
    total
    totalWithTax
    currencyCode
    subTotalWithTax
    shippingWithTax
    totalQuantity
    promotions {
      id
      name
      couponCode
    }
    couponCodes
    discounts {
      amount
      description
    }
    taxSummary {
      taxRate
      taxBase
      taxTotal
    }
    lines {
      id
      discountedLinePrice
      productVariant {
        currencyCode
        price
        assets {
          source
        }
        facetValues {
          id
          name
          facet {
            name
          }
        }
        options {
          code
          group {
            name
          }
        }
        name
        sku
        product {
          name
          slug
        }
      }
      unitPrice
      linePrice
      quantity
    }
  }
}
    `;
 const ActiveOrderCheckoutDocument = gql`
    query ActiveOrderCheckout {
  activeOrder {
    id
    state
    updatedAt
    createdAt
    total
    totalWithTax
    subTotal
    subTotalWithTax
    shipping
    currencyCode
    shippingWithTax
    totalQuantity
    promotions {
      id
      name
      couponCode
    }
    couponCodes
    discounts {
      amount
      description
    }
    taxSummary {
      taxRate
      taxBase
      taxTotal
    }
    shippingAddress {
      ...OrderAddress
    }
    billingAddress {
      ...OrderAddress
    }
    shippingLines {
      shippingMethod {
        id
        languageCode
        code
        name
        description
      }
    }
    lines {
      id
      linePrice
      discountedLinePrice
      unitPrice
      quantity
      productVariant {
        currencyCode
        id
        name
        sku
        price
        product {
          id
          name
          slug
        }
        facetValues {
          id
          name
          facet {
            name
          }
        }
        sku
        assets {
          id
          source
        }
      }
    }
  }
}
    ${OrderAddressFragmentDoc}`;
 const ActiveOrderNavigationDocument = gql`
    query ActiveOrderNavigation {
  activeOrder {
    lines {
      quantity
    }
  }
}
    `;
 const ActiveOrderOfferDocument = gql`
    query ActiveOrderOffer {
  activeOrder {
    id
  }
}
    `;
 const ActiveUserWishlistDocument = gql`
    query ActiveUserWishlist($id: ID!) {
  activeUserWishlist(id: $id) {
    id
    itemCount
    name
    createdAt
    items {
      id
      product {
        id
        name
        slug
        featuredAsset {
          source
        }
      }
      productVariant {
        id
        sku
        name
        price
        priceWithTax
        stockLevel
      }
      purchased
      lastPurchasedAt
    }
    description
    isPublic
    publicCode
  }
}
    `;
 const ActiveUserWishlistsDocument = gql`
    query ActiveUserWishlists {
  activeUserWishlists {
    id
    itemCount
    name
    createdAt
    items {
      id
      product {
        id
        name
        slug
      }
      productVariant {
        id
        sku
        name
        price
        priceWithTax
        stockLevel
      }
      purchased
      lastPurchasedAt
    }
    description
    isPublic
    publicCode
  }
}
    `;
 const AllCollectionsDocument = gql`
    query AllCollections {
  collections {
    items {
      breadcrumbs {
        ...Breadcrumb
      }
      slug
    }
  }
}
    ${BreadcrumbFragmentDoc}`;
 const AvailableCompaniesDocument = gql`
    query AvailableCompanies {
  companies {
    items {
      id
      name
      customFields {
        erpId
        vat
        ust
      }
      addresses {
        fullName
        streetLine1
      }
    }
  }
}
    `;
 const AvailableCountriesDocument = gql`
    query AvailableCountries {
  availableCountries {
    ...Country
  }
}
    ${CountryFragmentDoc}`;
 const CollectionDocument = gql`
    query Collection($slug: String) {
  collection(slug: $slug) {
    id
    slug
    customFields {
      seoDescription
    }
    breadcrumbs {
      ...Breadcrumb
    }
    name
    description
    assets {
      ...Asset
    }
    customFields {
      hasDetailPage
    }
  }
}
    ${BreadcrumbFragmentDoc}
${AssetFragmentDoc}`;
 const CustomerSpecificPriceDocument = gql`
    query CustomerSpecificPrice($channelCode: String!, $variantId: String!) {
  customerSpecificPrice(channelCode: $channelCode, variantId: $variantId) {
    basePrice
    price
  }
}
    `;
 const EligiblePaymentMethodsDocument = gql`
    query EligiblePaymentMethods {
  eligiblePaymentMethods {
    id
    code
    name
    description
    isEligible
    eligibilityMessage
    customFields
  }
}
    `;
 const EligibleShippingMethodsDocument = gql`
    query EligibleShippingMethods {
  eligibleShippingMethods {
    id
    price
    priceWithTax
    code
    name
    description
    metadata
    customFields
  }
}
    `;
 const NextOrderStatesDocument = gql`
    query NextOrderStates {
  nextOrderStates
}
    `;
 const OfferByIdDocument = gql`
    query OfferById($offerId: ID!) {
  offerById(offerId: $offerId) {
    id
    number
    date
    address
    subject
    headerText
    footerText
    termsOfDelivery
    termsOfPayment
    reference
    status
    additionalDiscount
    customOfferLines {
      id
      name
      price
      quantity
      taxRate
      discount
      positionTotal
      alternative
      productVariant {
        price
      }
    }
    company {
      id
      name
      customFields {
        erpId
        vat
        ust
      }
      addresses {
        streetLine1
        streetLine2
        province
        countryCode
        city
        postalCode
        phoneNumber
        defaultBillingAddress
      }
    }
    order {
      id
      totalWithTax
      lines {
        id
        customFields {
          offerOrderLine {
            id
            price
            quantity
            taxRate
            discount
            name
            alternative
            positionTotal
            productVariant {
              id
              price
              sku
              product {
                description
              }
            }
          }
        }
      }
    }
  }
}
    `;
 const OfferPositionVariantsDocument = gql`
    query OfferPositionVariants($skus: [String!]!) {
  variants(skus: $skus) {
    id
    name
    sku
    price
  }
}
    `;
 const OfferPriceDocument = gql`
    query offerPrice($productVariantId: ID!) {
  offerPrices(productVariantId: $productVariantId) {
    items {
      id
      price
      currencyCode
    }
  }
}
    `;
 const OfferPricesDocument = gql`
    query OfferPrices($take: Int, $skip: Int) {
  offerPrices(options: {take: $take, skip: $skip}) {
    totalItems
    items {
      ...OfferPrice
    }
  }
}
    ${OfferPriceFragmentDoc}
${AssetFragmentDoc}`;
 const OfferSettingsDocument = gql`
    query OfferSettings {
  offerSettings {
    id
    logo
    color
    vat
    iban
    accountNumber
    bankName
    bankCode
    address
    phoneNumber
    email
    companyRegister
  }
}
    `;
 const LatestOfferDocument = gql`
    query LatestOffer {
  offers(options: {sort: {number: DESC}, take: 1, skip: 0}) {
    id
    reference
    status
    createdAt
    number
    date
    address
    subject
    headerText
    footerText
    termsOfDelivery
    termsOfPayment
    order {
      lines {
        customFields {
          offerOrderLine {
            id
          }
        }
      }
    }
  }
}
    `;
 const OffersDocument = gql`
    query Offers($filter: OfferFilterOptions, $sort: OfferSortOptions, $take: Int, $skip: Int) {
  offers(options: {filter: $filter, sort: $sort, take: $take, skip: $skip}) {
    ...OfferFragment
  }
}
    ${OfferFragmentFragmentDoc}`;
 const OrderDocument = gql`
    query Order($code: String!) {
  orderByCode(code: $code) {
    id
    customer {
      id
      firstName
      lastName
      phoneNumber
    }
    code
    state
    active
    currencyCode
    shipping
    shippingAddress {
      fullName
      company
      streetLine1
      streetLine2
      city
      province
      postalCode
      country
      countryCode
      phoneNumber
      customFields
    }
    billingAddress {
      fullName
      company
      streetLine1
      streetLine2
      city
      province
      postalCode
      country
      countryCode
      phoneNumber
      customFields
    }
    shippingLines {
      shippingMethod {
        id
        languageCode
        code
        name
        description
      }
    }
    lines {
      id
      linePrice
      discountedLinePrice
      unitPrice
      quantity
      productVariant {
        currencyCode
        id
        name
        sku
        price
        product {
          id
          name
          slug
        }
        assets {
          id
          source
        }
      }
    }
    payments {
      method
    }
  }
}
    `;
 const PriceDocument = gql`
    query price($variantId: Int!) {
  pimcoreObjects(ids: [$variantId]) {
    productVariants {
      price
    }
  }
}
    `;
 const ProductDocument = gql`
    query Product($slug: String, $withCustomFields: Boolean = false, $isPreRender: Boolean = true) {
  product(slug: $slug) {
    id
    name
    description
    customFields {
      productDetailName
    }
    assets {
      ...Asset
    }
    collections {
      id
      breadcrumbs {
        ...Breadcrumb
      }
    }
    variants {
      ...ProductVariant
    }
    customFields {
      downloads {
        id
        fileName
        fileSize
        path
      }
    }
  }
}
    ${AssetFragmentDoc}
${BreadcrumbFragmentDoc}
${ProductVariantFragmentDoc}
${ProductVariantCustomFieldFragmentDoc}`;
 const ProductsDocument = gql`
    query Products($skip: Int) {
  products(options: {take: 20, skip: $skip}) {
    items {
      slug
      variants {
        id
      }
    }
  }
}
    `;
 const SearchDocument = gql`
    query Search($input: String!, $prefixMode: Boolean = true) {
  search(input: {term: $input, prefixMode: $prefixMode}) {
    items {
      id
      price {
        ... on SinglePrice {
          value
        }
      }
      productVariantAsset {
        preview
      }
      sku
      slug
      productId
      productName
      productVariantId
      productVariantName
      description
      customMappings {
        systemLine
        systemName
      }
    }
  }
}
    `;
 const VariantDocument = gql`
    query Variant($id: ID!, $withCustomFields: Boolean = false, $isPreRender: Boolean = true) {
  variant(variantId: $id) {
    ...ProductVariant
  }
}
    ${ProductVariantFragmentDoc}
${ProductVariantCustomFieldFragmentDoc}
${AssetFragmentDoc}`;
 const VariantDetailsDocument = gql`
    query VariantDetails($id: ID!, $includeImages: Boolean = false, $includeSystemInformation: Boolean = false, $includeDownloads: Boolean = false, $includeAdditionalProducts: Boolean = false) {
  variant(variantId: $id) {
    customFields {
      onSiteImages @include(if: $includeImages) {
        name
        source
      }
      onSiteImages @include(if: $includeImages) {
        name
        source
      }
      systemInformationItems @include(if: $includeSystemInformation) {
        itemText
        itemHeadline
      }
      downloads @include(if: $includeDownloads) {
        id
        fileName
        fileSize
        path
      }
      additionalProducts @include(if: $includeAdditionalProducts) {
        id
        slug
        featuredAsset {
          source
        }
        name
      }
    }
  }
}
    `;
 const VariantVendureIdDocument = gql`
    query variantVendureId($variantId: Int!) {
  pimcoreObjects(ids: [$variantId]) {
    productVariants {
      id
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    AddItemToOrder(variables: Types.AddItemToOrderMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.AddItemToOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AddItemToOrderMutation>(AddItemToOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddItemToOrder', 'mutation', variables);
    },
    addItemToOrderBySku(variables: Types.AddItemToOrderBySkuMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.AddItemToOrderBySkuMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AddItemToOrderBySkuMutation>(AddItemToOrderBySkuDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addItemToOrderBySku', 'mutation', variables);
    },
    AddPayment(variables: Types.AddPaymentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.AddPaymentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AddPaymentMutation>(AddPaymentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddPayment', 'mutation', variables);
    },
    AddToWishlist(variables: Types.AddToWishlistMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.AddToWishlistMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AddToWishlistMutation>(AddToWishlistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddToWishlist', 'mutation', variables);
    },
    UpdateCompanyAddress(variables: Types.UpdateCompanyAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.UpdateCompanyAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateCompanyAddressMutation>(UpdateCompanyAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCompanyAddress', 'mutation', variables);
    },
    DeleteCompanyAddress(variables: Types.DeleteCompanyAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.DeleteCompanyAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteCompanyAddressMutation>(DeleteCompanyAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteCompanyAddress', 'mutation', variables);
    },
    CreateCompanyAddress(variables: Types.CreateCompanyAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CreateCompanyAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateCompanyAddressMutation>(CreateCompanyAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateCompanyAddress', 'mutation', variables);
    },
    AdjustOrderLine(variables: Types.AdjustOrderLineMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.AdjustOrderLineMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AdjustOrderLineMutation>(AdjustOrderLineDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AdjustOrderLine', 'mutation', variables);
    },
    ApplyCouponCode(variables: Types.ApplyCouponCodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ApplyCouponCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ApplyCouponCodeMutation>(ApplyCouponCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ApplyCouponCode', 'mutation', variables);
    },
    CreateAddress(variables: Types.CreateAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CreateAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateAddressMutation>(CreateAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateAddress', 'mutation', variables);
    },
    CreateAsset(variables: Types.CreateAssetMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CreateAssetMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateAssetMutation>(CreateAssetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateAsset', 'mutation', variables);
    },
    createCustomerAddress(variables: Types.CreateCustomerAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CreateCustomerAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateCustomerAddressMutation>(CreateCustomerAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCustomerAddress', 'mutation', variables);
    },
    CreateOffer(variables: Types.CreateOfferMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CreateOfferMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateOfferMutation>(CreateOfferDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateOffer', 'mutation', variables);
    },
    CreateWishlist(variables: Types.CreateWishlistMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CreateWishlistMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateWishlistMutation>(CreateWishlistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateWishlist', 'mutation', variables);
    },
    DeleteOffer(variables: Types.DeleteOfferMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.DeleteOfferMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteOfferMutation>(DeleteOfferDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteOffer', 'mutation', variables);
    },
    DeleteWishlist(variables: Types.DeleteWishlistMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.DeleteWishlistMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteWishlistMutation>(DeleteWishlistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteWishlist', 'mutation', variables);
    },
    LoginUser(variables: Types.LoginUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.LoginUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.LoginUserMutation>(LoginUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LoginUser', 'mutation', variables);
    },
    LogoutUser(variables?: Types.LogoutUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.LogoutUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.LogoutUserMutation>(LogoutUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LogoutUser', 'mutation', variables);
    },
    SubscribeToNewsletter(variables: Types.SubscribeToNewsletterMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SubscribeToNewsletterMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SubscribeToNewsletterMutation>(SubscribeToNewsletterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SubscribeToNewsletter', 'mutation', variables);
    },
    UnsubscribeToNewsletter(variables: Types.UnsubscribeToNewsletterMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.UnsubscribeToNewsletterMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UnsubscribeToNewsletterMutation>(UnsubscribeToNewsletterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UnsubscribeToNewsletter', 'mutation', variables);
    },
    CreateOfferPrice(variables: Types.CreateOfferPriceMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CreateOfferPriceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateOfferPriceMutation>(CreateOfferPriceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateOfferPrice', 'mutation', variables);
    },
    UpdateOfferPrice(variables: Types.UpdateOfferPriceMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.UpdateOfferPriceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateOfferPriceMutation>(UpdateOfferPriceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateOfferPrice', 'mutation', variables);
    },
    DeleteOfferPrice(variables: Types.DeleteOfferPriceMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.DeleteOfferPriceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteOfferPriceMutation>(DeleteOfferPriceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteOfferPrice', 'mutation', variables);
    },
    CreateOfferPrices(variables: Types.CreateOfferPricesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CreateOfferPricesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateOfferPricesMutation>(CreateOfferPricesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateOfferPrices', 'mutation', variables);
    },
    PasswordReset(variables: Types.PasswordResetMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.PasswordResetMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.PasswordResetMutation>(PasswordResetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PasswordReset', 'mutation', variables);
    },
    Register(variables: Types.RegisterMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.RegisterMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RegisterMutation>(RegisterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Register', 'mutation', variables);
    },
    RemoveAllOrderLines(variables?: Types.RemoveAllOrderLinesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.RemoveAllOrderLinesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RemoveAllOrderLinesMutation>(RemoveAllOrderLinesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveAllOrderLines', 'mutation', variables);
    },
    removeAllOrderLinesAndStockMovements(variables?: Types.RemoveAllOrderLinesAndStockMovementsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.RemoveAllOrderLinesAndStockMovementsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RemoveAllOrderLinesAndStockMovementsMutation>(RemoveAllOrderLinesAndStockMovementsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeAllOrderLinesAndStockMovements', 'mutation', variables);
    },
    RemoveCouponCode(variables: Types.RemoveCouponCodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.RemoveCouponCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RemoveCouponCodeMutation>(RemoveCouponCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveCouponCode', 'mutation', variables);
    },
    RemoveFromWishlist(variables: Types.RemoveFromWishlistMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.RemoveFromWishlistMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RemoveFromWishlistMutation>(RemoveFromWishlistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveFromWishlist', 'mutation', variables);
    },
    RemoveItemFromOrder(variables: Types.RemoveItemFromOrderMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.RemoveItemFromOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RemoveItemFromOrderMutation>(RemoveItemFromOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveItemFromOrder', 'mutation', variables);
    },
    RequestPasswordReset(variables: Types.RequestPasswordResetMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.RequestPasswordResetMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RequestPasswordResetMutation>(RequestPasswordResetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RequestPasswordReset', 'mutation', variables);
    },
    SetActiveCompany(variables: Types.SetActiveCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SetActiveCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetActiveCompanyMutation>(SetActiveCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetActiveCompany', 'mutation', variables);
    },
    SetShippingAddress(variables: Types.SetShippingAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SetShippingAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetShippingAddressMutation>(SetShippingAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetShippingAddress', 'mutation', variables);
    },
    SetBillingAddress(variables: Types.SetBillingAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SetBillingAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetBillingAddressMutation>(SetBillingAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetBillingAddress', 'mutation', variables);
    },
    SetOrderCustomFields(variables?: Types.SetOrderCustomFieldsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SetOrderCustomFieldsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetOrderCustomFieldsMutation>(SetOrderCustomFieldsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetOrderCustomFields', 'mutation', variables);
    },
    SetOrderShippingMethod(variables: Types.SetOrderShippingMethodMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SetOrderShippingMethodMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetOrderShippingMethodMutation>(SetOrderShippingMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetOrderShippingMethod', 'mutation', variables);
    },
    ToogleCustomerSpecificPrice(variables?: Types.ToogleCustomerSpecificPriceMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ToogleCustomerSpecificPriceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ToogleCustomerSpecificPriceMutation>(ToogleCustomerSpecificPriceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ToogleCustomerSpecificPrice', 'mutation', variables);
    },
    transformOfferToCart(variables: Types.TransformOfferToCartMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.TransformOfferToCartMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.TransformOfferToCartMutation>(TransformOfferToCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'transformOfferToCart', 'mutation', variables);
    },
    TransitionOrder(variables: Types.TransitionOrderMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.TransitionOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.TransitionOrderMutation>(TransitionOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TransitionOrder', 'mutation', variables);
    },
    UpdateCustomer(variables: Types.UpdateCustomerMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.UpdateCustomerMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateCustomerMutation>(UpdateCustomerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCustomer', 'mutation', variables);
    },
    UpdateCustomerPassword(variables: Types.UpdateCustomerPasswordMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.UpdateCustomerPasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateCustomerPasswordMutation>(UpdateCustomerPasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCustomerPassword', 'mutation', variables);
    },
    RequestUpdateCustomerEmail(variables: Types.RequestUpdateCustomerEmailMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.RequestUpdateCustomerEmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RequestUpdateCustomerEmailMutation>(RequestUpdateCustomerEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RequestUpdateCustomerEmail', 'mutation', variables);
    },
    UpdateOffer(variables: Types.UpdateOfferMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.UpdateOfferMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateOfferMutation>(UpdateOfferDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateOffer', 'mutation', variables);
    },
    UpdateOfferSettings(variables?: Types.UpdateOfferSettingsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.UpdateOfferSettingsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateOfferSettingsMutation>(UpdateOfferSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateOfferSettings', 'mutation', variables);
    },
    UpdateWishlist(variables: Types.UpdateWishlistMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.UpdateWishlistMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateWishlistMutation>(UpdateWishlistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateWishlist', 'mutation', variables);
    },
    ActiveChannel(variables?: Types.ActiveChannelQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveChannelQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveChannelQuery>(ActiveChannelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveChannel', 'query', variables);
    },
    ActiveCompany(variables?: Types.ActiveCompanyQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveCompanyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveCompanyQuery>(ActiveCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveCompany', 'query', variables);
    },
    ActiveCompanyAddresses(variables?: Types.ActiveCompanyAddressesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveCompanyAddressesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveCompanyAddressesQuery>(ActiveCompanyAddressesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveCompanyAddresses', 'query', variables);
    },
    ContactPersonId(variables?: Types.ContactPersonIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ContactPersonIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ContactPersonIdQuery>(ContactPersonIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ContactPersonId', 'query', variables);
    },
    ActiveCustomer(variables?: Types.ActiveCustomerQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveCustomerQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveCustomerQuery>(ActiveCustomerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveCustomer', 'query', variables);
    },
    ActiveCustomerRoles(variables?: Types.ActiveCustomerRolesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveCustomerRolesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveCustomerRolesQuery>(ActiveCustomerRolesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveCustomerRoles', 'query', variables);
    },
    ActiveCustomerForCheckout(variables?: Types.ActiveCustomerForCheckoutQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveCustomerForCheckoutQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveCustomerForCheckoutQuery>(ActiveCustomerForCheckoutDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveCustomerForCheckout', 'query', variables);
    },
    ActiveCustomerOrders(variables?: Types.ActiveCustomerOrdersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveCustomerOrdersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveCustomerOrdersQuery>(ActiveCustomerOrdersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveCustomerOrders', 'query', variables);
    },
    ActiveOrderCart(variables?: Types.ActiveOrderCartQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveOrderCartQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveOrderCartQuery>(ActiveOrderCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveOrderCart', 'query', variables);
    },
    ActiveOrderCheckout(variables?: Types.ActiveOrderCheckoutQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveOrderCheckoutQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveOrderCheckoutQuery>(ActiveOrderCheckoutDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveOrderCheckout', 'query', variables);
    },
    ActiveOrderNavigation(variables?: Types.ActiveOrderNavigationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveOrderNavigationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveOrderNavigationQuery>(ActiveOrderNavigationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveOrderNavigation', 'query', variables);
    },
    ActiveOrderOffer(variables?: Types.ActiveOrderOfferQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveOrderOfferQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveOrderOfferQuery>(ActiveOrderOfferDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveOrderOffer', 'query', variables);
    },
    ActiveUserWishlist(variables: Types.ActiveUserWishlistQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveUserWishlistQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveUserWishlistQuery>(ActiveUserWishlistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveUserWishlist', 'query', variables);
    },
    ActiveUserWishlists(variables?: Types.ActiveUserWishlistsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ActiveUserWishlistsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ActiveUserWishlistsQuery>(ActiveUserWishlistsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActiveUserWishlists', 'query', variables);
    },
    AllCollections(variables?: Types.AllCollectionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.AllCollectionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AllCollectionsQuery>(AllCollectionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AllCollections', 'query', variables);
    },
    AvailableCompanies(variables?: Types.AvailableCompaniesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.AvailableCompaniesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AvailableCompaniesQuery>(AvailableCompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AvailableCompanies', 'query', variables);
    },
    AvailableCountries(variables?: Types.AvailableCountriesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.AvailableCountriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AvailableCountriesQuery>(AvailableCountriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AvailableCountries', 'query', variables);
    },
    Collection(variables?: Types.CollectionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CollectionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CollectionQuery>(CollectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Collection', 'query', variables);
    },
    CustomerSpecificPrice(variables: Types.CustomerSpecificPriceQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.CustomerSpecificPriceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CustomerSpecificPriceQuery>(CustomerSpecificPriceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CustomerSpecificPrice', 'query', variables);
    },
    EligiblePaymentMethods(variables?: Types.EligiblePaymentMethodsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.EligiblePaymentMethodsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.EligiblePaymentMethodsQuery>(EligiblePaymentMethodsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EligiblePaymentMethods', 'query', variables);
    },
    EligibleShippingMethods(variables?: Types.EligibleShippingMethodsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.EligibleShippingMethodsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.EligibleShippingMethodsQuery>(EligibleShippingMethodsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EligibleShippingMethods', 'query', variables);
    },
    NextOrderStates(variables?: Types.NextOrderStatesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.NextOrderStatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.NextOrderStatesQuery>(NextOrderStatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'NextOrderStates', 'query', variables);
    },
    OfferById(variables: Types.OfferByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.OfferByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.OfferByIdQuery>(OfferByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OfferById', 'query', variables);
    },
    OfferPositionVariants(variables: Types.OfferPositionVariantsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.OfferPositionVariantsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.OfferPositionVariantsQuery>(OfferPositionVariantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OfferPositionVariants', 'query', variables);
    },
    offerPrice(variables: Types.OfferPriceQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.OfferPriceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.OfferPriceQuery>(OfferPriceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'offerPrice', 'query', variables);
    },
    OfferPrices(variables?: Types.OfferPricesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.OfferPricesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.OfferPricesQuery>(OfferPricesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OfferPrices', 'query', variables);
    },
    OfferSettings(variables?: Types.OfferSettingsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.OfferSettingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.OfferSettingsQuery>(OfferSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OfferSettings', 'query', variables);
    },
    LatestOffer(variables?: Types.LatestOfferQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.LatestOfferQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.LatestOfferQuery>(LatestOfferDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LatestOffer', 'query', variables);
    },
    Offers(variables?: Types.OffersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.OffersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.OffersQuery>(OffersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Offers', 'query', variables);
    },
    Order(variables: Types.OrderQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.OrderQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.OrderQuery>(OrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Order', 'query', variables);
    },
    price(variables: Types.PriceQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.PriceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.PriceQuery>(PriceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'price', 'query', variables);
    },
    Product(variables?: Types.ProductQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ProductQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ProductQuery>(ProductDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Product', 'query', variables);
    },
    Products(variables?: Types.ProductsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.ProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ProductsQuery>(ProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Products', 'query', variables);
    },
    Search(variables: Types.SearchQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.SearchQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SearchQuery>(SearchDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Search', 'query', variables);
    },
    Variant(variables: Types.VariantQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.VariantQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.VariantQuery>(VariantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Variant', 'query', variables);
    },
    VariantDetails(variables: Types.VariantDetailsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.VariantDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.VariantDetailsQuery>(VariantDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'VariantDetails', 'query', variables);
    },
    variantVendureId(variables: Types.VariantVendureIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<Types.VariantVendureIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.VariantVendureIdQuery>(VariantVendureIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'variantVendureId', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;